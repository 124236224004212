import React, { useRef } from 'react';
import EventBus from 'eventbusjs';

import config from './../config.js';
import helpers from './../helpers.js';
//import Slider from './Slider.js';

function Progressbar(props) {
	const progressBar = useRef(false);

	return <div className="progress w-full h-[5px] max-w-[600px] bg-sam_dark/20" ref={progressBar} onClick={(event) => {
		var rect = progressBar.current.getBoundingClientRect();

		var x = event.clientX-rect.left;
		var width = rect.width;

		if (props.onSelect) {
			props.onSelect(props.total*(x/width));
		}
	}}>
		<div className="progress-bar h-[5px] bg-sam_dark/80" style={{width: (props.progress && props.total ? (props.progress / props.total)*100 : 0)+'%'}} />
	</div>
}

class GlobalAudioPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.audioCanPlayHandler = this.audioCanPlayHandler.bind(this);
		this.audioEndedHandler = this.audioEndedHandler.bind(this);
		this.audioPlayHandler = this.audioPlayHandler.bind(this);
		this.audioPauseHandler = this.audioPauseHandler.bind(this);
		this.durationSliderChangeHandler = this.durationSliderChangeHandler.bind(this);
		this.togglePlay = this.togglePlay.bind(this);

		this.state = {
			loaded: false,
			playing: false,
			paused: false,
			id: null,
			title: null,
			info: null,
			durationTime: 0,
			currentTime: 0
		};
	}

	componentDidMount() {
		if (!window.eventBus) {
			window.eventBus = EventBus;
		}

		window.ismusAudioPlayer = {
			player: this,
			currentAudio: {
				id: null,
				audioUrl: null,
				title: null,
				playing: false,
				paused: false
			}
		};

		this.audio = new Audio();

		this.audio.addEventListener('canplaythrough', this.audioCanPlayHandler);
		this.audio.addEventListener('ended', this.audioEndedHandler);
		this.audio.addEventListener('play', this.audioPlayHandler);
		this.audio.addEventListener('pause', this.audioPauseHandler);
		this.audio.addEventListener('error', this.audioErrorHandler);

		if (window.eventBus) {
			window.eventBus.addEventListener('audio.playaudio', function(event) {
				this.playAudio(event.target);
			}.bind(this));
			window.eventBus.addEventListener('audio.pauseaudio', function(event) {
				this.pauseAudio();
			}.bind(this));
			window.eventBus.addEventListener('audio.seek', (event) => {
				this.audio.currentTime = event.target;
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pathname != this.props.pathname) {
			this.setState({
				loaded: false,
				playing: false
			});

			this.pauseAudio();
		}
	}

	audioCanPlayHandler(event) {
		if (this.state.loaded) {
			return;
		}

		this.setState({
			loaded: true
		});

		if (window.eventBus) {
			window.eventBus.dispatch('audio.playervisible');
		}

		this.audio.play();

		this.durationInterval = setInterval(function() {
			if (this.state.playing) {
				this.setState({
					currentTime: this.audio.currentTime,
					durationTime: this.audio.duration
				});
				window.eventBus.dispatch('audio.progress', this.audio.currentTime);
			}
		}.bind(this), 100);

		setTimeout(() => {
			this.initPhase = false;
		}, 1000);
	}

	audioEndedHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = false;
		window.ismusAudioPlayer.currentAudio.paused = false;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.stop', {
				paused: false
			});
		}

		this.setState({
			paused: false,
			playing: false
		});

		clearInterval(this.durationInterval);
	}

	audioPlayHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = true;
		window.ismusAudioPlayer.currentAudio.paused = false;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.play');
		}

		this.setState({
			playing: true,
			paused: false
		});
	}

	audioPauseHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = false;
		window.ismusAudioPlayer.currentAudio.paused = true;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.stop', {
				paused: true
			});
		}

		this.setState({
			playing: false,
			paused: true
		});
	}

	audioErrorHandler(event) {
		if (window.eventBus) {
			//window.eventBus.dispatch('popup-notification.notify', null, l('Kan inte spela den här ljudfilen'));
		}
	}

	togglePlay() {
		if (this.state.loaded) {
			if (this.state.playing) {
				this.audio.pause();
			}
			else {
				this.audio.play();
			}
		}
	}

	pauseAudio() {
		if (this.state.loaded && this.state.playing) {
			this.audio.pause();
		}
	}

	resumeAudio() {
		if (this.state.loaded && this.state.paused) {
			this.audio.play();
		}
	}

	durationSliderChangeHandler(event) {
		if (!this.initPhase) {
			this.audio.currentTime = event.target.value[0];
		}
	}

	playAudio(data) {
		if (window.ismusAudioPlayer.currentAudio.id == data.id && 
			window.ismusAudioPlayer.currentAudio.audio == data.audio && 
			this.state.paused &&
			this.state.loaded) {
			this.resumeAudio();
		}
		else {
			window.ismusAudioPlayer.currentAudio.id = data.id;
			window.ismusAudioPlayer.currentAudio.audioUrl = data.audioUrl;

			let stateObj = {
				loaded: false,
				playing: false,
				audioUrl: data.audioUrl,
				title: data.title,
				info: data.info,
				id: data.id
			};

			if (data.dataTypeUrl) {
				stateObj.dataTypeUrl = data.dataTypeUrl;
			}
			else {
				stateObj.dataTypeUrl = undefined;
			}

			this.setState(stateObj);

			this.initPhase = true;

			this.audio.src = data.audioUrl.startsWith('http://') || data.audioUrl.startsWith('https://') ? data.audioUrl : config.mediaUrl+data.audioUrl;
			this.audio.load();
		}
	}

	render() {
		return <div className={'fixed bottom-0 left-0 w-full max-w-[800px] bg-sam_light px-6 pt-3 pb-6 z-[1500] shadow-[-7px_-7px_25px_black] transition-all  '+(this.state.loaded ? ' translate-y-0' : ' translate-y-[200px]')}>
			<div className="bg-sam_light absolute top-[-31px] left-0 right-0 h-[70px] skew-y-2 shadow-[0px_-5px_4px_#0000000a]"></div>

			<div className="bg-sam_light absolute right-[-10px] top-[-17px] bottom-0 w-[40px] skew-x-[8deg]"></div>

			<div className={'global-audio-player relative'} disabled={!this.state.loaded}>

				<div className="flex gap-4">
					<button area-label={window.l('Smelltu hér til að spila eða stöðva hljóðupptöku')} className={'play-button border border-sam_dark/50 border-1 rounded-[100%] w-[50px] h-[50px] min-w-[50px] min-h-[50px] bg-no-repeat bg-center bg-[length:30px_30px] bg-sam_light'+(this.props.className ? ' '+this.props.className : '')}
						style={{
							backgroundImage: this.state.playing ? 'url(/img/icon-pause.png)' : 'url(/img/icon-play.png)'
						}}
						onClick={this.togglePlay}></button>

					<div className="w-full">
						<div className="player-time mb-2 flex text-md">
							<div className="min-w-[100px] text-sam_dark/80">
								{helpers.formatDuration(this.state.currentTime*1000)} / <span className="duration">{helpers.formatDuration(this.state.durationTime*1000)}</span>
							</div>
							{
								this.state.title &&
								<div className="title">{this.state.title}</div>
							}

						</div>

						<div className="player-content">
							<Progressbar progress={this.state.currentTime}
								total={this.state.durationTime}
								className="audio-seek-slider"
								onSelect={(event) => {
									if (!this.initPhase) {
										this.audio.currentTime = event;
									}
								}} />
						</div>

					</div>

				</div>


				<button area-label={window.l('Smelltu hér til að loka hljóðspilara')} className="hidden btn close-button" onClick={() => {
					this.setState({
						loaded: false
					});

					this.pauseAudio();
				}}>
					<div className="close-icon white" />
				</button>

			</div>

		</div>;
	}
}

export default GlobalAudioPlayer;
