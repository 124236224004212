import { useEffect, useState } from "react";
import helpers from "../helpers";
import config from "../config";
import _ from 'underscore';

function OpeningHours(props) {
	const [timeData, setTimeData] = useState(null);
	const [openingHoursData, setOpeningHoursData] = useState(null);

	useEffect(() => {
		if (!timeData) {
			fetch(config.apiRoot+'openinghours')
				.then(res => res.json())
				.then(json => setTimeData(json.results));
		}
		if (!openingHoursData) {
			fetch(config.apiRoot+'openinghours_text')
				.then(res => res.json())
				.then(json => setOpeningHoursData(json.results));
		}
	}, []);

	const today = (new Date()).getDay();

	return <div className={props.className+' group opening-hours cursor-pointer'}>
		{
			timeData && <>
				<div className="relative z-10">
					{
						_.findWhere(timeData, {day: today}).open_from && _.findWhere(timeData, {day: today}).open_to ? <>
							<div className="font-medium text-2xl text-sam_accent group-hover:text-sam_darkblue">{window.l('Opið í dag')}</div>
							<div className="text-xl text-white">{helpers.formatOpeningHours(_.findWhere(timeData, {day: today}))}</div>
						</> : <div className="font-medium text-2xl text-white transition-all duration-500 group-hover:text-sam_darkblue">{window.l('Lokað í dag')}</div>
						
		
					}
				</div>

				<div className={'absolute bg-white/95 rounded p-10 pt-20 -top-8 -right-10 min-w-[530px] transition-all duration-500 opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-all'}>
					<table className="w-full text-xl">
						<tbody>
							{
								openingHoursData && <table className="w-full">
									<tbody>
										{
											openingHoursData.map((item, index) => <tr key={index}>
												<td className="text-sam_darkblue">{item['label_'+props.currentLang]}</td>
												<td className="text-sam_red">{item['openinghours_'+props.currentLang].length > 1 ? item['openinghours_'+props.currentLang] : ''}</td>
											</tr>)
										}
									</tbody>
								</table>
							}
						</tbody>
					</table>
				</div>
			</>
		}
	</div>
}

export default OpeningHours;