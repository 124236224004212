import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

window.langData = {
	'Forsíða': 'Front Page',
	'Aðgangseyrir': 'Prices',
	'Afgreiðslutími': 'Opening Hours',
	'Til baka á forsíðu': 'Go back',
	'Sýningin': 'The Exhibition',
	'Forsíða': 'Front page',
	'Ókeypis': 'Free',

	'Heimur í orðum': 'World in Words',
	'Heimur í': 'World in',
	'orðum': 'Words',

	'Handritin': 'The Manuscripts',
	'Handritin á sýningunni': 'The Manuscripts',
	'Til sýnis í kaflanum': 'On display in the capter',

	'Handritasýning í': 'Manuscript Exhibition',
	' Eddu': ' in Edda',

	'Opið í dag': 'Open today',
	'Lokað í dag': 'Closed today',
	'Lokað til 7. janúar': 'Closed until January 7th',

	'Konungsbók Eddukvæða er til sýnis frá': 'The Codex Regius is on display from',
	'til': 'till',
	'Konungsbók Eddukvæða verður aftur til sýnis frá': 'The Codex Regius will be on display from',
	'Sjá nánar.': 'See more.',
	'Sjá myndir á': 'See images at',

	'Smelltu hér til að fara á forsíðu': 'Click here to go to front page',
	'Smelltu hér til að fara á vefsíðu Árnastofnunar': 'Click here to go to homepage of The Árni Magnússon Institute for Icelandic Studies',
	'Merki UNESCO, Menningarmálastofnun Sameinuðu þjóðanna': 'Logo for UNESCO, the United Nations Educational, Scientific and Cultural Organization',

	'Kort tengt efni kaflans': 'Map related to the chapter content',

	'Click here to switch website language to English': 'Smelltu hér til að breyta tungumáli vefsíðu yfir á Íslensku',

	'Smelltu hér til að opna valmynd': 'Click here to open main menu',
	'Smelltu hér til að spila eða stöðva hljóðupptöku': 'Click here to play or pause audio recording',
	'Smelltu hér til að loka hljóðspilara': 'Click here to close audio player',
	'Sjá nánari upplýsingar um handritið': 'See more information about the manuscript',
	'Spila hljóðupptöku': 'Play audio',
	
	'Símanúmer': 'Tel.'
};

window.l = (s) => {
	let lang;

	try {
		lang = window.localStorage.getItem('currentLang');
	}
	catch (e) {
		lang = 'is';
	}
	return lang == 'is' ? s : window.langData[s] ? window.langData[s] : s;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
