import { useState, useEffect } from "react";
import helpers from "../../helpers";

export default function Image(props) {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => setLoaded(true), 800);
	}, [props.src]);

	return <img alt={props.alt} onLoad={(event) => setLoaded(true)} src={helpers.mediaUrl(props.src)} className={props.className} style={{
		transition: 'opacity 300ms ease-in-out',
		opacity: loaded ? 1 : 0
	}} />
}
