import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react"
import _ from 'underscore';
import config from '../config';
import Icon from './elements/Icon';

function useInterval(callback, delay, limit) {
    const [count, setCount] = useState(1);
    const intervalRef = useRef();
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback
    }, [callback]);

    useEffect(() => {
        if ((typeof delay === 'number' && count <= limit) || (typeof delay === 'number' && !limit)) {
            intervalRef.current = window.setInterval(() => {
                setCount(c => c + 1)
                callbackRef.current(count, setCount)
            }, delay)
            return () => {
                window.clearInterval(intervalRef.current)
            }
        }
    }, [delay, count]);

    return intervalRef;
}

export default function SearchBox(props) {
	const [searchIndex, setSearchIndex] = useState(null);
	const [keywords, setKeywords] = useState(null);
	const [keywordCounter, setKeywordCounter] = useState(0);
	const [searchSuggestions, setSearchSuggestions] = useState({});
	const [inputFocus, setInputFocus] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [expanded, setExpanded] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!searchIndex && props.siteData) {
			let index = {
				is: {},
				en: {}
			};
	
			let addIndexItem = (language, key, path, title) => {
				if (!index[language][key]) {
					index[language][key] = [];
				}
				index[language][key].push({path: path, title: title});
			}
			
			const languages = ['is', 'en'];
	
			languages.forEach((lang) => {
				props.siteData.forEach(chapter => {
					//addIndexItem(lang, chapter['title_'+lang], [chapter.path], [chapter['title_'+lang]]);
		
					chapter.pages.forEach(page => {
						addIndexItem(lang, page['title_'+lang], [chapter.path, page.path], [chapter['title_'+lang], page['title_'+lang]]);
	
						if (page.chapter_page_manuscripts && page.chapter_page_manuscripts.length > 0) {
							page.chapter_page_manuscripts.forEach(manuscript => {
								addIndexItem(lang, manuscript['manuscript_name_'+lang], [chapter.path, page.path], [chapter['title_'+lang], page['title_'+lang]])
							})
						}

						page.keywords.forEach(keyword => {
							addIndexItem(lang, keyword['keyword_'+lang], [chapter.path, page.path], [chapter['title_'+lang], page['title_'+lang]])
						});
		
						page.subpages.forEach(subpage => {
							addIndexItem(lang, subpage['title_'+lang], [chapter.path, page.path, subpage.path], [chapter['title_'+lang], page['title_'+lang], subpage['title_'+lang]]);
		
							subpage.keywords.forEach(keyword => {
								addIndexItem(lang, keyword['keyword_'+lang], [chapter.path, page.path, subpage.path], [chapter['title_'+lang], page['title_'+lang], subpage['title_'+lang]])
							});
						});
					})
				})
			});
	
			setSearchIndex(index);

			let keywordsShuffled = shuffle(Object.keys(index[props.currentLang]));
			setKeywords(keywordsShuffled);
		}
	}, [props.siteData]);

	useInterval(() => {
		if (keywords) {
			if (keywordCounter+1 > keywords.length) {
				setKeywordCounter(0);
			}
			else {
				setKeywordCounter(keywordCounter+1);
			}
		}
	}, 5000);

	const shuffle = (array) => {
		let currentIndex = array.length;

		while (currentIndex != 0) {
			let randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
		}

		return array;
	};

	const inputChangeHandler = (event) => {
		setInputValue(event.target.value);

		if (event.target.value.length > 0) {
			let foundSuggestions = {};
			for (let key in searchIndex[props.currentLang]) {
				if (key.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1) {
					if (!foundSuggestions[key]) {
						foundSuggestions[key] = []
					}
					foundSuggestions[key] = foundSuggestions[key].concat(searchIndex[props.currentLang][key]);
					foundSuggestions[key] = _.uniq(foundSuggestions[key], (item) => item.path.join('/'));
				}
			}

			setSearchSuggestions(foundSuggestions)
		}
		else {
			setSearchSuggestions({});
		}
	};

	return <div className={props.className}>

		{
			!props.small &&
			<div className="hidden md:block text-xl text-white pb-2">{window.l('Hverju ertu að leita að?')}</div>
		}

		<div className="relative">
			{
				keywords &&
				<div className={(!expanded ? 'hidden' : '')+' md:block absolute inset-0 pointer-events-none max-w-full overflow-hidden mr-[58px] transition-all duration-[600ms]'+(inputFocus || inputValue.length > 0 ? ' opacity-0' : '')}>
					{
						keywords.map((keyword, index) => <div key={index} className={'text-nowrap absolute text-xl text-white p-3 transition-all duration-[600ms]'+(keywordCounter < index ? ' -translate-y-5 opacity-0' : keywordCounter > index ? ' translate-y-5 opacity-0' : '')}>{keyword}?</div>)
					}
				</div>
			}


			<input type="text" value={inputValue} onChange={inputChangeHandler} onKeyDown={(event) => {
					if (event.key == 'Enter' && Object.keys(searchSuggestions).length > 0) {
						navigate('/'+config.exhibitionPageRoot+'/'+searchSuggestions[Object.keys(searchSuggestions)[0]][0].path.join('/'))
						setSearchSuggestions({});
					}
				}} 
				onFocus={() => setInputFocus(true)} 
				onBlur={() => setInputFocus(false)} 
				className={(expanded ? 'max-w-[500px]' : 'max-w-14')+' md:max-w-[500px] w-[300px] bg-sam_light/20 p-3 text-xl text-white rounded transition-all'} 
			/>

			<button className={'absolute top-4 right-4'} onClick={() => setExpanded(!expanded)}>
				<Icon icon={'magnifier'} fillColor={'#fff'} strokeColor={'#fff'} />
			</button>

			{
				Object.keys(searchSuggestions).length > 0 && <div className="absolute top-14 left-0 right-0 max-h-[calc(100vh-280px)] overflow-y-scroll bg-sam_light rounded divide-y z-10">
					{
						Object.keys(searchSuggestions).map((key, keyIndex) => <div key={keyIndex}>
							{
								Object.keys(searchSuggestions).length > 0 && <div className="p-4 border-b" dangerouslySetInnerHTML={{__html: key.replace(new RegExp('('+inputValue+')' , 'ig'), '<strong class="text-sam_red">$&</strong>')}} />
							}
							{
								searchSuggestions[key].map((item, index) => <Link onClick={() => {
									setSearchSuggestions({});
								}} className="text-sam_red text-lg underline block p-4 pl-6" to={'/'+config.exhibitionPageRoot+'/'+item.path.join('/')} dangerouslySetInnerHTML={{__html: item.title.join(' / ')}} />)
							}
						</div>)
					}
				</div>
			}
		</div>

	</div>
}