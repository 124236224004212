import { useEffect, useState, useRef } from 'react';
import { Outlet, useOutlet, useLocation, useNavigate, Link } from 'react-router-dom';
import Image from './elements/Image';
import PlinthMenu from './PlinthMenu';
import config from '../config';
import helpers from '../helpers';
import _ from 'underscore';
import SearchBox from './SearchBox';

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

function HeaderBackground(props) {
	const [headerBackground, setHeaderBackground] = useState(null);
	const [initialized, setInitialized] = useState(false);
	const [headerBackgroundTop, setHeaderBackgroundTop] = useState(0);

	const onWindowScroll = (event) => {
		let windowHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		
		//setHeaderBackgroundTop(window.scrollY);

		let v = window.scrollY/windowHeight;

		//document.getElementById('headerBackground').style.top = (window.scrollY*(v))+'px'

		if (document.getElementById('headerBackground')) {
			document.getElementById('headerBackground').style.top = (window.scrollY-(window.scrollY/5))+'px'
		}
	}

	useEffect(() => {
		setHeaderBackgroundTop(0);
		window.addEventListener('scroll', onWindowScroll);

		return () => {
			window.removeEventListener('scroll', onWindowScroll);
		}
	}, [])

	useEffect(() => {
		try {
			if (props.headerBackground.url == headerBackground.url) {
				return;
			}
		}
		catch (e) {}

		if (headerBackground) {
			setInitialized(false);

			setTimeout(() => {
				setHeaderBackground(null);

				setTimeout(() => {
					setHeaderBackground(props.headerBackground);
					setInitialized(true);
				}, 100);
			}, 600);
		}
		else {
			setHeaderBackground(null);

			setTimeout(() => {
				setHeaderBackground(props.headerBackground);
				setInitialized(true);
			}, 100);
		}
	}, [props.headerBackground]);

	return <div className={'video-wrapper overflow-hidden absolute top-0 left-0 right-0 bottom-0 transition-opacity duration-[600ms]'+(initialized ? ' opacity-100' : ' opacity-0')}>

		{
			headerBackground && headerBackground.type == 'image' &&
			<div id="headerBackground" style={{
				backgroundImage: 'url('+helpers.mediaUrl(headerBackground.url)+')',
				//top: headerBackgroundTop
			}} className="absolute w-full h-full bg-center bg-cover" />
		}

		{
			headerBackground && headerBackground.type == 'video' &&
			<video id="headerBackground" 
				autoPlay={true} 
				muted={true} 
				loop={true} 
				controls={false}
				playsInline={true}
				poster={'/img/video_fallback.jpg'}
				src={config.videoRoot+headerBackground.url+'.mp4'} 
				className="w-full h-full object-cover" style={{
					backgroundImage: 'url(/img/video_fallback.png)',
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			>
				<source src={config.videoRoot+headerBackground.url+'.webm'} type="video/webm" />
				<source src={config.videoRoot+headerBackground.url+'.mp4'} type="video/mp4" />
				<img aria-hidden="true" src="/img/video_fallback.jpg" />
			</video>
		}

		<div className="absolute w-full top-0 left-0 h-full bg-[#383337] opacity-60"></div>

	</div>
}

function ExhibitionPageView(props) {
	const [initialized, setInitialized] = useState(false);
	const [imageInitialized, setImageInitialized] = useState(false);
	const [manuscriptImage, setManuscriptImage] = useState(null);
	const [subpageHeaderBackground, setSubpageHeaderBackground] = useState(null);
	const [displayManuscriptImage, setDisplayManuscriptImage] = useState(null);
	const [headerContent, setHeaderContent] = useState(null);

	const location = useLocation();
	const navigate = useNavigate();
	const prevValue = usePrevious({location});

	const outlet = useOutlet();

	const urlBits = (url) => _.compact(url.split('/'));

	useEffect(() => {
		setHeaderContent(props.headerContent);
		setTimeout(() => setInitialized(true), 200);

		return () => {
			props.onThemeColor(null);
		}
	}, []);

	useEffect(() => {
		if (!outlet && subpageHeaderBackground) {
			setSubpageHeaderBackground(null);
		}
	}, [outlet]);

	useEffect(() => {
		if (props.onThemeColor) {
			props.onThemeColor(props.themeColor);
		}
	}, [props.themeColor]);

	useEffect(() => {
		if (prevValue && urlBits(prevValue.location.pathname)[1] != urlBits(location.pathname)[1]) {
			setInitialized(false);
			setTimeout(() => {
				setInitialized(true);
				setHeaderContent(props.headerContent);
			}, 200);
		}
		
		if (urlBits(location.pathname).length == 2) {
			setImageInitialized(false);

			setTimeout(() => {
				setDisplayManuscriptImage(null);
			}, 800);
		}
		else {
			console.log(manuscriptImage)
			if (!manuscriptImage) {
				setImageInitialized(false);
				setDisplayManuscriptImage(null);
			}
			else if (displayManuscriptImage && displayManuscriptImage.manuscript_image != manuscriptImage.manuscript_image) {
				setImageInitialized(false);

				setTimeout(() => {
					setDisplayManuscriptImage(manuscriptImage);
					setImageInitialized(true);
				}, 800);
			}
			else if (!displayManuscriptImage && manuscriptImage) {
				setDisplayManuscriptImage(manuscriptImage);

				setTimeout(() => setImageInitialized(true), 800);
			}
		}
	}, [location, manuscriptImage]);

	return <div className={'relative inline-block w-full min-h-[100vh] '+(initialized ? 'transition-opacity duration-[600ms] opacity-100' : 'opacity-0')}>
		<div className="relative">

			<SearchBox className="absolute top-20 md:top-7 right-5 md:right-52 z-40" small={true} currentLang={props.currentLang} siteData={props.siteData} />

			<PlinthMenu className="!absolute top-[30px] right-[115px] z-30 hidden lg:block" 
				small={true} 
				plinthClick={(event, item) => navigate('/'+config.exhibitionPageRoot+item.path)}/>

			<div className="page-header-container">

				{
					displayManuscriptImage && <Link to={'/handrit/'+displayManuscriptImage.manuscript.split(' ').join('-').replace('.', '')} className={'group no-underline page-header-image '+(imageInitialized ? ' translate-y-0 scale-100 opacity-100' : '-translate-y-6 scale-90 opacity-0')}>
					<Image alt={displayManuscriptImage['manuscript_name_'+props.currentLang]} src={displayManuscriptImage.manuscript_image} />
					<div className="hidden md:block page-header-image-text">
						<div className="text-xl">{displayManuscriptImage.manuscript}</div>
						{
							displayManuscriptImage['manuscript_name_'+props.currentLang] && <div>{displayManuscriptImage['manuscript_name_'+props.currentLang]}</div>
						}
					</div>
				</Link>
				}
	
				<div className="page-header-content">
					{
						headerContent
					}
				</div>

				<HeaderBackground headerBackground={subpageHeaderBackground || props.headerBackground} />

			</div>
		</div>

		<div className="subpage-container">
			<div className="subpage-content relative">
				{
					props.mainContent
				}
				<Outlet context={{setManuscriptImage, setSubpageHeaderBackground}} />
			</div>
		</div>


	</div>
}

export default ExhibitionPageView;